<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <PilotHeaderMobile></PilotHeaderMobile>
    <!-- end:: Header Mobile -->

    <pilot-loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></pilot-loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <PilotAside v-if="asideEnabled"></PilotAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <PilotHeader
          v-bind:breadcrumbs="breadcrumbs"
          v-bind:title="pageTitle"
        />
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <!-- begin:: Notice -->
              <b-alert
                v-if="!currentUser.company.onboarding_complete"
                show
                variant="warning"
                dismissible
              >
                Your company administrator has not completed the company setup
                so certain modules might be in view only mode.<br />In the
                meantime feel free to look around and get acquainted with Pilot
              </b-alert>
              <!-- end:: Notice -->

              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
    <ScrollTop></ScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PilotAside from "@/view/layout/aside/Aside.vue";
import PilotHeader from "@/view/layout/header/Header.vue";
import PilotHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import Footer from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import ScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/pilot-loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "layout",
  components: {
    PilotAside,
    PilotHeader,
    PilotHeaderMobile,
    Footer,
    ScrollTop,
    "pilot-loader": Loader
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
