<template>
  <ul class="menu-nav">
    <li class="menu-section mb-3 mt-0">
      <div class="d-flex align-items-center">
        <div
          class="symbol symbol-40 mr-5 align-self-start align-self-xxl-center"
        >
          <div class="symbol-label circle" :style="companyLogo"></div>
        </div>
        <div>
          <div class="text-white">
            <h6 class="mb-0 pb-0 text-company-name">
              {{ currentUser.company.name }}
            </h6>
          </div>
        </div>
      </div>
    </li>
    <li class="menu-section">
      <h4 class="menu-text">Home</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-pie-chart-4"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Observation</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/observations/create"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-edit"></i>
          <span class="menu-text">Log Observations</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/observations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-tasks"></i>
          <span class="menu-text">View Observations</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Energy & Waste</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/energy-logging"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-bolt"></i>
          <span class="menu-text">Energy Management</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/waste-logging"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-trash-alt"></i>
          <span class="menu-text">Waste Management</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="hasPermission('Administrator')">
      <h4 class="menu-text">Users & Groups</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      v-if="hasPermission('Administrator')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/users')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far fa-user"></i>
        <span class="menu-text">User Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/users"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Manage Users</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/users/invites"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Manage Invites</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/users/invite"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Invite Users</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      v-if="hasPermission('Administrator')"
      to="/action-parties"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Action Parties</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="hasPermission('Administrator')">
      <h4 class="menu-text">Settings & Preferences</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      v-if="hasPermission('Administrator')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/settings/streams')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-industry"></i>
        <span class="menu-text">Energy & Waste</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/energy-streams"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Energy Streams</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/waste-streams"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Waste Streams</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="hasPermission('Administrator')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/settings/observation')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-glasses"></i>
        <span class="menu-text">Observation Settings</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/observation-categories"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Observation Categories</span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            to="/observation-types"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Observation Types</span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>

    <router-link
      v-if="hasPermission('Administrator')"
      to="/locations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-compass"></i>
          <span class="menu-text">Locations Settings</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="hasPermission('Administrator')"
      to="/company-profile"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-building"></i>
          <span class="menu-text">Company Profile</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PilotMenu",
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser",
      "hasPermission",
      "layoutConfig"
    ]),

    companyLogo() {
      if (this.currentUser.company.logo != null) {
        return `background-image: url(${this.currentUser.company.logo})`;
      } else {
        return `background-image: url(${this.layoutConfig("placeholder")})`;
      }
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
