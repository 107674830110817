<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUser.firstname }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="currentUser.avatar" alt="Pic" :src="currentUser.avatar" />
        <span
          v-if="!currentUser.avatar"
          class="symbol-label font-size-h5 font-weight-bold"
        >
          {{ currentUser.firstname.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          My Profile
          <small class="text-muted font-size-sm ml-2"></small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>

      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
              class="symbol-label border border-secondary circle shadow-sm"
              :src="photo"
              alt=""
            />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/user/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ fullname }}
            </router-link>
            <div class="text-muted mt-1">
              <span class="badge badge-primary">{{ rolename }}</span>
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email }}
                  </span>
                </span>
              </a>
            </div>
            <button
              class="btn btn-sm btn-light-primary btn-bold"
              @click="onLogout"
            >
              Sign out
            </button>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <div class="navi navi-spacer-x-0 p-0">
          <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/user/profile">
                  <div class="font-weight-bold">Account Management</div>
                </router-link>
                <div class="text-muted">
                  Account settings & password
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            to="/observations/assigned"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Assigned Observations</div>
                <div class="text-muted">Observations assigned to me</div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="separator separator-dashed my-7"></div>
        <div>
          <h5 class="mb-5">Most Recent Observations</h5>
          <template v-for="(item, i) in observations">
            <div
              class="d-flex align-items-center rounded p-5 mb-3"
              v-bind:class="`bg-light-${color(item.status.name)}`"
              v-bind:key="i"
            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-${color(item.status.name)}`"
              >
                <span class="svg-icon svg-icon-lg">
                  <inline-svg :src="icon(item.status.name)" />
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  @click="details(item.id)"
                  v-b-tooltip.hover.top="item.title"
                  style="max-width: 230px;"
                  class="font-weight-bold text-dark-75 text-hover-primary font-size-lg text-truncate cursor-pointer"
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-weight-bold font-size-sm">
                  {{ item.status.name }} | created
                  <time-ago
                    class="text-sm text-dark-75 font-weight-bold"
                    :refresh="60"
                    :datetime="item.timestamp"
                    locale="en"
                    :long="true"
                    tooltip
                  ></time-ago>
                </span>
              </div>
            </div>
          </template>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ObservationService from "@/core/services/observation.service.js";
import { TimeAgo } from "vue2-timeago";

export default {
  name: "QuickUser",
  data() {
    return {
      observations: []
    };
  },
  components: {
    TimeAgo
  },
  mounted() {
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.getRecent();
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    color(status) {
      console.log(status);
      if (status == "Open") return "danger";
      else if (status == "Pending") return "warning";
      else return "success";
    },
    icon(status) {
      console.log(status);
      if (status == "Open") return "media/svg/icons/code/time-schedule.svg";
      else if (status == "Pending")
        return "media/svg/icons/home/alarm-clock.svg";
      else return "media/svg/icons/code/done-circle.svg";
    },
    details(id) {
      this.$router.push(`/observations/details/${id}`);
    },
    getRecent() {
      ObservationService.recent(5)
        .then(response => {
          this.observations = response.data;
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),

    fullname() {
      return this.currentUser.firstname + " " + this.currentUser.lastname;
    },
    rolename() {
      switch (this.currentUser.role_id) {
        case 1:
          return "Administrator";
        case 2:
          return "Editor";
        case 3:
          return "Contributor";
        default:
          return "Contributor";
      }
    },
    photo() {
      return this.currentUser.avatar == null
        ? this.layoutConfig("avatar")
        : this.currentUser.avatar;
    }
  }
};
</script>
